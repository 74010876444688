.yz_modal_add_web_m {
  &.ant-modal {
    width: calc(100vw - 1.706666rem) !important;

    .ant-modal-content {
      width: auto !important;
      border-radius: 8px;

      .ant-modal-body {
        padding: .64rem;

        &>.modal_header_web {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: .266666rem;

          &>.modal_title_web {
            font-size: .48rem;
            font-weight: 500;
            color: #171717;
            line-height: .666666rem;
          }

          &>.modal_close_web {
            display: flex;
            justify-content: center;
            align-items: center;
            width: .64rem;
            height: .64rem;

            .modal_close_img_web {
              width: 100%;
              height: 100%;
            }
          }
        }

        &>.modal_body_web {
          display: flex;
          flex-direction: column;

          &>.web_input_box {
            display: flex;
            flex-direction: column;
            margin-top: .266666rem;
            padding: .16rem 0;

            &>span {
              font-size: .4rem;
              font-weight: 400;
              color: #171717;
              line-height: .56rem;
            }

            &>.web_input_box_main {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: .213333rem;
              padding: .266666rem .426666rem;
              height: 1.066666rem;
              border: 0.5px solid #B0B0B0;
              border-radius: 4px;

              &>input {
                margin: 0;
                width: 100%;
                height: 100%;
                font-size: .373333rem;
                font-weight: 400;
                color: #171717;
                line-height: .373333rem;
                border: none;
                background-color: transparent;
                outline: none;

                &::placeholder {
                  font-size: .373333rem;
                  font-weight: 400;
                  color: #999;
                  line-height: .373333rem;
                }
              }
            }
          }

          &>.web_select_box {
            display: flex;
            flex-direction: column;
            margin-top: .266666rem;
            padding: .16rem 0;

            &>span {
              font-size: .4rem;
              font-weight: 400;
              color: #171717;
              line-height: .56rem;
            }

            &>.web_select_box_main {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: .213333rem;
              padding: .266666rem .426666rem;
              height: 1.386666rem;
              border: 0.5px solid #B0B0B0;
              border-radius: 4px;

              &>.web_select_box_main_content {
                position: absolute;
                left: 0;
                top: 100%;
                padding: .0520833rem 0;
                width: 100%;
                max-height: 4rem;
                background-color: #FAFAFA;
                border-radius: 0 0 4px 4px;
                transition: all 200ms;
                overflow-y: auto;

                &>.web_select_box_main_content_item {
                  display: flex;
                  align-items: center;
                  padding: 0 .426666rem;
                  width: 100%;
                  height: 1.28rem;
                  font-size: .373333rem;
                  font-weight: 400;
                  color: #171717;
                  line-height: .373333rem;

                  &.active {
                    background-color: #DDD;
                  }

                  &:hover {
                    background-color: #DDD;
                  }
                }
              }

              &>span {
                width: 100%;
                font-size: .373333rem;
                font-weight: 400;
                color: #171717;
              }

              &>img {
                width: .853333rem;
                height: .853333rem;
              }
            }
          }

          &>.web_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: .8rem;
            width: 100%;
            height: 1.146666rem;
            background-color: #C90D17;
            border-radius: 4px;
            cursor: pointer;

            &>span {
              font-size: .4rem;
              font-weight: 700;
              color: #FFF;
              line-height: .4rem;
            }
          }
        }
      }
    }
  }
}