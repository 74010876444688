.about_honor_m {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem;
  background-color: #FFF;

  &>.title {
    font-size: .64rem;
    font-weight: 500;
    color: #171717;
    line-height: .9067rem;
  }

  &>.about_honor_img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: .5333rem 0;
    &>img {
      width: 7.7333rem;
      height: 5.8133rem;
    }
  }

  &>.box1 {
    display: flex;
    flex-direction: column;
    padding: 0 .8rem;
    &>span {
      padding-bottom: 4px;
      font-size: .48rem;
      font-weight: 500;
      color: #171717;
      line-height: .6667rem;
    }
    &>.list {
      display: flex;
      flex-direction: column;
      &>.item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
        &>.yuandian {
          min-width: .1333rem;
          width: .1333rem;
          height: .1333rem;
          background-color: #C90D17;
          border-radius: 50%;
        }
        &>.item_title {
          margin-left: .2133rem;
          font-size: .3467rem;
          font-weight: 400;
          color: #666;
          line-height: .48rem;
        }
      }
    }
  }
  &>.box2 {
    display: flex;
    flex-direction: column;
    padding: 0 .8rem;
    &>span {
      padding-bottom: 4px;
      font-size: .48rem;
      font-weight: 500;
      color: #171717;
      line-height: .6667rem;
    }
    &>.list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      &>.item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: .2133rem;
        width: 50%;
        &.padding_left {
          padding-left: .2133rem;
        }
        &.padding_right {
          padding-right: .2133rem;
        }
        &>.yuandian {
          min-width: .1333rem;
          width: .1333rem;
          height: .1333rem;
          background-color: #C90D17;
          border-radius: 50%;
        }
        &>.item_title {
          margin-left: .2133rem;
          font-size: .3467rem;
          font-weight: 400;
          color: #666;
          line-height: .48rem;
        }
      }
    }
  }
  &>.margin_top {
    margin-top: .8533rem;
  }
}