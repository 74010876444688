.home_section {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-width: 6.875rem;
  overflow-x: hidden;
  overflow-y: auto;

  &>.ant-carousel {
    height: 100% !important;

    .slick-slider,
    .slick-vertical,
    .slick-initialized {
      height: 100% !important;

      .slick-list {
        height: 100% !important;

        .slick-track {
          height: 100% !important;

          .slick-slide {
            height: 100% !important;

            &>div {
              height: 100% !important;

              &>div {
                height: 100% !important;
              }
            }
          }
        }
      }
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    // height: 100%;
  }
}