.pdh_header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 90px;

  &>.pdh_header_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 6.875rem;
    &>span {
      font-size: 14px;
      font-weight: 400;
      color: #333;
      line-height: 14px;
      user-select: none;
      cursor: pointer;
      &.active {
        color: #C90D17;
      }
    }
    &>.to_right {
      padding: 0 3px;
      font-size: 14px;
      font-weight: 400;
      color: #333;
      line-height: 14px;
    }
  }
}