.product_details {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  background-color: transparent;
  
  @media screen and (max-width: 540px) {
    font-size: 16px;
    
    img {
      max-width: 100%;
      height: auto;
    }
    
    iframe, video {
      max-width: 100%;
      height: auto;
    }
  }
}