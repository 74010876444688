.home_introduce_m {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem;
  background-color: #FAFAFA;

  &>.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: .64rem;

    span:nth-child(1) {
      font-size: .64rem;
      font-weight: 500;
      color: #C90D17;
      line-height: .9067rem;
    }

    span:nth-child(2) {
      margin-top: .0533rem;
      font-size: .32rem;
      font-weight: 700;
      color: #999999;
      line-height: .4533rem;
    }
  }

  &>.content {
    font-size: .3733rem;
    font-weight: 400;
    color: #666666;
    line-height: .5867rem;
    text-align: justify;
  }

  &>.icon_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: .1067rem;

    &>.icon_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: .5333rem;
      width: 50%;
      height: 1.1733rem;

      .icon_img {
        width: 1.1733rem;
        height: 1.1733rem;
      }

      .icon_box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: .32rem;
        height: 1.1733rem;

        .icon_title {
          font-size: .56rem;
          font-weight: 600;
          color: #C90D17;
          line-height: .56rem;
        }

        .icon_content {
          font-size: .32rem;
          font-weight: 400;
          color: #666;
          line-height: .4533rem;
        }
      }
    }
  }

  &>.map {
    position: relative;
    margin-top: .8533rem;

    .map_img {
      width: 9.0133rem;
      height: 7.5467rem;
    }
    .map_content {
      position: absolute;
      left: .08rem;
      bottom: .1067rem;
      &>.map_label {
        display: flex;
        flex-direction: row;
        align-items: center;
        &.margin_top {
          margin-top: .2133rem;
        }
  
        .dot {
          margin-left: .08rem;
          margin-right: .16rem;
          width: .24rem;
          height: .24rem;
          background-color: #C90D17;
          border-radius: 50%;
        }
        
        img {
          margin-right: .16rem;
          width: .3733rem;
          height: .3733rem;
        }
        span {
          font-size: .3467rem;
          font-weight: 400;
          color: #171717;
          line-height: .48rem;
        }
      }
    }
  }
}

.divider {
  width: 100%;
  height: .16rem;
  background-color: #EAEAEA;
}