.home_partners_m {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem;
  background-color: #FFF;

  &>.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: .533333rem;

    span:nth-child(1) {
      font-size: .64rem;
      font-weight: 500;
      color: #C90D17;
      line-height: .9067rem;
    }

    span:nth-child(2) {
      margin-top: .0533rem;
      font-size: .32rem;
      font-weight: 700;
      color: #999999;
      line-height: .4533rem;
    }
  }

  &>.content {
    font-size: .3733rem;
    font-weight: 400;
    color: #666666;
    line-height: .5867rem;
    text-align: justify;
  }

  &>.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &>.item {
      display: flex;
      flex-direction: column;
      padding-top: .5333rem;
      width: 50%;
      &>.item_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        &>span {
          font-size: .4267rem;
          font-weight: 500;
          color: #171717;
          line-height: .5867rem;
        }
        &>.divider {
          margin: .1067rem 0;
          width: .5333rem;
          height: 2px;
          background-color: #C90D17;
        }
        &>.item_list {
          display: flex;
          flex-direction: column;
          align-items: center;
          &>.item_list_item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: .2133rem;
            border: 1px solid #EAEAEA;
            &>img {
              width: 2.6667rem;
              height: 1.0667rem;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}