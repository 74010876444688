.aub_advantage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
  overflow: hidden;

  &>.main_box {
    flex: 1;
    width: 6.875rem;

    &>.content {
      display: flex;
      flex-direction: row;
      padding: .4167rem 0;

      &>.information {
        display: flex;
        flex-direction: column;
        width: 100%;

        &>.title {
          font-size: .25rem;
          font-weight: 500;
          color: #171717;
          line-height: .2917rem;
          text-align: end;
        }
        &>.list {
          display: flex;
          flex-direction: row;
          padding-top: .2604rem;
          height: max-content;
          &>.item {
            display: flex;
            flex-direction: column;
            margin-left: .2604rem;
            padding-bottom: 19px;
            width: 2.1146rem;
            height: 100%;
            &.margin_left {
              margin-left: 0;
            }
            &>.item_box {
              position: relative;
              display: flex;
              flex-direction: column;
              padding: .125rem;
              width: 100%;
              height: 100%;
              background-color: #F5F5F5;
              transition: background-color 500ms ease-out;
              &>span:nth-child(1) {
                font-size: 24px;
                font-weight: 500;
                color: #171717;
                line-height: 28px;
                text-align: justify;
                transition: color 300ms ease-out;
                cursor: default;
              }
              &>span:nth-child(2) {
                margin-top: 4px;
                font-size: 14px;
                font-weight: 400;
                color: #666;
                line-height: 20px;
                text-align: justify;
                transition: color 300ms ease-out;
                cursor: default;
              }
              &>.to_bottom_san {
                position: absolute;
                top: 100%;
                left: 50%;
                width: 0;
                height: 0;
                transform: translateX(-50%);
                border: 0 solid transparent;
                border-top: 0 solid transparent;
                transition: all 300ms ease-out;
              }
            }
            &.active {
              &>.item_box {
                background-color: #C90D17;
                &>span:nth-child(1) {
                  color: #FFF;
                }
                &>span:nth-child(2) {
                  color: #FFF;
                }
                &>.to_bottom_san {
                  border-width: 10px;
                  border-top-color: #C90D17;
                }
              }
            }
          }
        }
        &>.tabs {
          padding: .125rem;
          background-color: #F5F5F5;
          &>.tabs_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: .1042rem;
            &.margin_top {
              margin-top: 0;
            }
            &>.yuan {
              min-width: 10px;
              width: 10px;
              height: 10px;
              background-color: #C90D17;
              border-radius: 50%;
            }
            &>span {
              margin-left: .0521rem;
              font-size: 14px;
              font-weight: 400;
              color: #171717;
              line-height: 16px;
            }
          }
        }
        &>.tabs2 {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: .2083rem .2083rem .0833rem;
          background-color: #F5F5F5;
          &>.tabs_item {
            display: flex;
            flex-direction: row;
            padding: 0 .2188rem .125rem;
            width: 25%;
            height: .5417rem;
            &>.tabs_item_box {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              &>img {
                width: 100%;
                height: .4167rem;
                object-fit: cover;
              }
            }
          }
        }
        &>.tab_content {
          padding: .2083rem;
          background-color: #F5F5F5;
          overflow: hidden;
          &>.tab_content_box {
            width: 100%;
            img {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}