.home_news_m {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem;
  background-color: #FAFAFA;

  &>.title {
    display: flex;
    flex-direction: column;
    align-items: center;

    span:nth-child(1) {
      font-size: .64rem;
      font-weight: 500;
      color: #C90D17;
      line-height: .9067rem;
    }

    span:nth-child(2) {
      margin-top: .0533rem;
      font-size: .32rem;
      font-weight: 700;
      color: #999999;
      line-height: .4533rem;
    }
  }

  &>.list {
    display: flex;
    flex-direction: column;

    &>.item {
      display: flex;
      flex-direction: column;
      margin-top: .8rem;
      &.margin_top {
        margin-top: .853333rem;
      }
      &>img {
        min-width: 100%;
        width: 100%;
        height: 4.1333rem;
        border-radius: 4px;
        object-fit: cover;
      }
      &>span {
        margin-top: .32rem;
        font-size: .4267rem;
        font-weight: 500;
        color: .8533rem;
        line-height: .8533rem;
      }
    }
  }

  &>.btn {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .8rem;
    padding: .2133rem 1.0667rem;
    width: max-content;
    background-color: #C90D17;
    border-radius: 4px;
    cursor: pointer;
    &:active {
      background-color: rgba(201, 13, 23, 0.8);
    }

    &>span {
      font-size: .4rem;
      font-weight: 400;
      color: #FFF;
      line-height: .72rem;
    }
  }
}