.pd_main {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &>.pd_main_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 6.1979rem;
    min-width: 6.1979rem;
    &>.pd_main_box_header {
      display: flex;
      flex-direction: column;
      padding: 40px 0;
      &>.title {
        font-size: 36px;
        font-weight: 600;
        color: #171717;
        line-height: 42px;
      }
      &>.time {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 16px;
        &>img {
          width: 18px;
          height: 18px;
        }
        &>span {
          margin-left: 6px;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 16px;
        }
      }
      &>.jianjie {
        margin-top: 16px;
        font-size: 15px;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
      }
    }
    &>.pd_main_box_content {
      padding: 40px 0;
      border-top: 1px solid #EDEDED;
      border-bottom: 1px solid #EDEDED;
      height: max-content;
      word-break: break-all;
    }
  }
}