.yz_select_box {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &>.yz_select_box_item {
    max-width: 3.2rem;
    font-size: .3733rem;
    font-weight: 400;
    color: #171717;
    line-height: .5333rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &>img {
    width: .8533rem;
    height: .8533rem;
  }

  &>.yz_select_list {
    position: absolute;
    left: -.32rem;
    top: .8533rem;
    display: flex;
    flex-direction: column;
    padding: .16rem 0;
    width: 3.2rem;
    max-height: 4.533333rem;
    background-color: #FFF;
    box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.08);
    overflow-y: auto;

    &>.yz_select_item {
      display: flex;
      padding: .2133rem .4267rem;
      max-width: 100%;

      &>span {
        width: 100%;
        font-size: .3733rem;
        font-weight: 400;
        color: #171717;
        line-height: .5333rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &:active {
        background-color: #ccc;
      }
    }
  }
}