.about_course {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5208rem 0;
  // height: 4.2083rem;
  background-color: #FAFAFA;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .about_course_header {
    display: flex;
    flex-direction: column;
    min-width: 6.875rem;
    max-width: 3.8073rem;

    .about_course_title {
      font-size: .25rem;
      font-weight: 500;
      color: #171717;
      line-height: .349rem;
    }
    .about_course_content {
      margin-top: .0417rem;
      font-size: 15px;
      font-weight: 400;
      color: #666;
      line-height: 27px;
      white-space: pre-wrap;
      text-align: justify;
    }
    .course_img {
      &>img {
        width: 100%;
      }
    }
  }
}