.aub_company {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
  overflow: hidden;

  &>.main_box {
    flex: 1;
    width: 6.875rem;

    &>.content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: .5208rem 0;

      &>.map {
        margin-left: .4271rem;
        height: max-content;

        .map_img {
          width: 3.8021rem;
          height: 3.1771rem;
        }
      }

      &>.information {
        display: flex;
        flex-direction: column;

        &>.title {
          font-size: .25rem;
          font-weight: 500;
          color: #171717;
          line-height: .2917rem;
        }
        &>.list {
          display: flex;
          flex-direction: column;
          padding-top: .2344rem;
          &>.item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: .125rem;
            padding: 0 .375rem 0 .125rem;
            width: 2.6458rem;
            height: .4271rem;
            background-color: #F5F5F5;
            &>.yuan {
              width: 15px;
              height: 15px;
              background-color: #C90D17;
              border-radius: 50%;
            }
            &>.title {
              flex: 1;
              margin-left: .0833rem;
              font-size: 24px;
              font-weight: 400;
              color: #171717;
              line-height: 24px;
            }
            &>.type {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}