.product_sort_m {
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;

  &>.screen {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 .8533rem;
    height: 1.28rem;
    background-color: #FAFAFA;

    &>.screen_item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &>.list {
    display: flex;
    flex-direction: column;
    padding: .853333rem .426666rem 0;

    &>.item {
      display: flex;
      flex-direction: column;
      padding: .533333rem 0;
      &.padding_top {
        padding-top: 0;
      }

      &>img {
        min-width: 100%;
        width: 100%;
        height: 4.133333rem;
        object-fit: cover;
      }

      &>.item_title {
        margin-top: .533333rem;
        font-size: .4267rem;
        font-weight: 700;
        color: #171717;
        line-height: .5867rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      &>.item_content {
        margin-top: .32rem;
        font-size: .3467rem;
        font-weight: 400;
        color: #696969;
        line-height: .4rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-align: justify;
      }

      &>.item_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: .64rem;
        &>.left {
          align-self: flex-end;
          display: flex;
          flex-direction: row;
          align-items: center;
          &>img {
            min-width: .48rem;
            width: .48rem;
            height: .48rem;
          }
          &>span {
            margin-left: .16rem;
            font-size: .32rem;
            font-weight: 400;
            color: #9C9C9C;
            line-height: .32rem;
          }
        }
        &>.btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: .0533rem .64rem;
          border: 1px solid #C90D17;
          border-radius: 37px;
          &:active {
            background-color: #C90D17;
            &>span {
              color: #FFF;
            }
          }
          &>span {
            font-size: .3467rem;
            font-weight: 400;
            color: #C90D17;
            line-height: .72rem;
          }
        }
      }
    }
  }

  &>.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.1867rem;
  }
}