.home_new {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
  overflow: hidden;

  .main_box {
    flex: 1;
    width: 6.875rem;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: .4167rem;

      span:nth-child(1) {
        font-size: .25rem;
        font-weight: 500;
        color: #C90D17;
        line-height: .3542rem;
      }

      span:nth-child(2) {
        font-size: .125rem;
        font-weight: 400;
        color: #999999;
        line-height: .1771rem;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      margin-top: .4688rem;
      margin-bottom: .5521rem;

      .map {
        position: relative;
        height: max-content;

        .map_img {
          width: 2.9792rem;
          height: 3.2604rem;
        }

        .map_lab_box {
          position: absolute;
          left: .0208rem;
          bottom: .0625rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          .map_label {
            display: flex;
            flex-direction: row;
            align-items: center;
            &.margin_left {
              margin-left: 27px;
            }
            &>img {
              width: 16px;
              height: 16px;
            }
            .dot {
              margin-right: .0417rem;
              width: 8px;
              height: 8px;
              background-color: #C90D17;
              border-radius: 50%;
            }

            span {
              font-size: .0729rem;
              font-weight: 400;
              color: #171717;
              line-height: .1042rem;
            }
          }
        }
      }

      .information {
        display: flex;
        flex-direction: column;
        margin-left: .3646rem;
        width: 100%;

        &>.icon_list {
          display: flex;
          flex-direction: column;
          width: 100%;

          .icon_item {
            margin-bottom: .2083rem;
            width: 100%;

            .icon_box {
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;

              .icon_img {
                width: 1.6146rem;
                height: .7292rem;
                object-fit: cover;
              }

              .icon_title {
                flex: 1;
                margin-left: .2083rem;
                font-size: 18px;
                font-weight: 500;
                color: #171717;
                line-height: 26px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-align: justify;
              }
            }
          }
        }
        &>.btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 144px;
          height: 48px;
          background-color: #C90D17;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            background-color: rgba(201, 13, 23, 0.8);
          }
  
          span {
            font-size: 16px;
            font-weight: 500;
            color: #FFF;
            line-height: 27px;
          }
        }
      }
    }
  }
}