.yz_modal_add_web {
  &.ant-modal {
    width: auto !important;

    .ant-modal-content {
      width: auto !important;
      border-radius: 8px;

      .ant-modal-body {
        padding: .125rem;

        &>.modal_header_web {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: .0520835rem;

          &>.modal_title_web {
            font-size: .125rem;
            font-weight: 500;
            color: #000;
            line-height: .1770835rem;
          }

          &>.modal_close_web {
            display: flex;
            justify-content: center;
            align-items: center;
            width: .125rem;
            height: .125rem;

            .modal_close_img_web {
              width: 100%;
              height: 100%;
            }
          }
        }

        &>.modal_body_web {
          display: flex;
          flex-direction: column;
          width: 2.2083333rem;

          &>.modal_body_web_content {
            margin-top: .0833333rem;
            font-size: .0833333rem;
            font-weight: 400;
            color: #333;
            line-height: .125rem;
            &>span {
              margin: 0 .0208333rem;
              font-size: .0833333rem;
              font-weight: 500;
              color: #C90D17;
              line-height: .125rem;
            }
          }

          &>.modal_body_web_btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: .1875rem;
            &>.web_btn {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: .2291666rem;
              background-color: #C90D17;
              border-radius: 4px;
              cursor: pointer;
              &.left {
                margin-right: .0520833rem;
                background-color: #FFF;
                border: 1px solid #C90D17;
                &>span {
                  font-size: .0833333rem;
                  font-weight: 700;
                  color: #C90D17;
                  line-height: .1041666rem;
                }
              }
              &>span {
                font-size: .0833333rem;
                font-weight: 700;
                color: #FFF;
                line-height: .1041666rem;
              }
            }
          }
        }
      }
    }
  }
}