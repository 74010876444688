.home_introduce {
  // flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 0 1.5625rem;
  background-color: #FAFAFA;
  overflow: hidden;

  .main_box {
    flex: 1;
    width: 6.875rem;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: .4167rem;

      span:nth-child(1) {
        font-size: .25rem;
        font-weight: 500;
        color: #C90D17;
        line-height: .3542rem;
      }

      span:nth-child(2) {
        font-size: .125rem;
        font-weight: 400;
        color: #999999;
        line-height: .1771rem;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      // align-items: center;
      margin-top: .4688rem;
      margin-bottom: .5521rem;

      .map {
        position: relative;
        height: max-content;

        .map_img {
          width: 3.6667rem;
          height: 3.0729rem;
        }

        .map_lab_box {
          position: absolute;
          left: .0208rem;
          bottom: .0625rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          .map_label {
            display: flex;
            flex-direction: row;
            align-items: center;
            &.margin_left {
              margin-left: 27px;
            }
            &>img {
              width: 16px;
              height: 16px;
            }
            .dot {
              margin-right: .0417rem;
              width: 8px;
              height: 8px;
              background-color: #C90D17;
              border-radius: 50%;
            }

            span {
              font-size: .0729rem;
              font-weight: 400;
              color: #171717;
              line-height: .1042rem;
            }
          }
        }
      }

      .information {
        display: flex;
        flex-direction: column;
        margin-left: .2188rem;

        .label {
          padding: .0521rem 0 .0521rem .1667rem;
          font-size: 15px;
          font-weight: 400;
          color: #666666;
          line-height: 27px;
          text-align: justify;
        }

        .icon_list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .icon_item {
            width: 50%;

            .icon_box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: .1042rem 0;

              .icon_img {
                width: .3646rem;
                height: .3646rem;
              }

              .icon_title {
                margin-top: .0625rem;
                font-size: .1875rem;
                font-weight: 700;
                color: #C90D17;
                line-height: .2188rem;
              }

              .icon_content {
                font-size: 15px;
                font-weight: 400;
                color: #666;
                line-height: 27px;
              }
            }
          }
        }
      }
    }
  }
}