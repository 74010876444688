.home_custom_made_m {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem;
  background-color: #FAFAFA;

  &>.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: .533333rem;

    span:nth-child(1) {
      font-size: .64rem;
      font-weight: 500;
      color: #C90D17;
      line-height: .9067rem;
    }

    span:nth-child(2) {
      margin-top: .0533rem;
      font-size: .32rem;
      font-weight: 700;
      color: #999999;
      line-height: .4533rem;
    }
  }

  &>.list {
    display: flex;
    flex-direction: column;

    &>.item {
      display: flex;
      flex-direction: row;
      padding-top: .4267rem;
      &>img {
        min-width: .8533rem;
        width: .8533rem;
        height: .8533rem;
      }
      &>.item_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: .4267rem;
        padding-bottom: .4267rem;
        border-bottom: 1px solid #EAEAEA;
        &>span:first-child {
          font-size: .533333rem;
          font-weight: 400;
          color: #171717;
          line-height: .7467rem;
        }
        &>span:last-child {
          margin-top: .1067rem;
          font-size: .3467rem;
          font-weight: 400;
          color: #696969;
          line-height: .48rem;
          text-align: justify;
        }
      }
    }
  }
}