.about_honors {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  overflow: hidden;

  .main_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 6.875rem;

    .title {
      margin-top: .4688rem;
      font-size: .25rem;
      font-weight: 500;
      color: #171717;
      line-height: .2917rem;
    }

    .content {
      display: flex;
      flex-direction: row;
      margin-top: .4167rem;
      margin-bottom: .5521rem;

      .map {
        padding-top: .0938rem;
        height: max-content;

        .map_img {
          width: 3.5208rem;
          height: 2.651rem;
        }
      }

      .information {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: .3646rem;
        
        .box1 {
          display: flex;
          flex-direction: row;
          padding-bottom: .2083rem;
          & > .box_list1 {
            display: flex;
            flex-direction: column;
            width: 50%;
            & > span {
              font-size: 28px;
              font-weight: 500;
              color: #171717;
              line-height: 33px;
            }
            & > .list {
              display: flex;
              flex-direction: column;
              & > .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 8px;
                &.margin_top {
                  margin-top: 12px;
                }
                & > .yuandian {
                  min-width: 5px;
                  width: 5px;
                  height: 5px;
                  background-color: #C90D17;
                  border-radius: 50%;
                }
                & > .item_title {
                  margin-left: 16px;
                  font-size: 15px;
                  font-weight: 400;
                  color: #666666;
                  line-height: 18px;
                  text-align: justify;
                  word-break: break-all;
                }
              }
            }
          }
          & > .box1_divider {
            min-width: .2604rem;
          }
        }
        .box2 {
          display: flex;
          flex-direction: column;
          & > span {
            padding-bottom: 4px;
            font-size: 28px;
            font-weight: 500;
            color: #171717;
            line-height: 33px;
          }
          & > .list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            & > .item {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 50%;
              margin-top: 8px;
              &.padding_left {
                padding-left: .1302rem;
              }
              & > .yuandian {
                min-width: 5px;
                width: 5px;
                height: 5px;
                background-color: #C90D17;
                border-radius: 50%;
              }
              & > .item_title {
                margin-left: 16px;
                font-size: 15px;
                font-weight: 400;
                color: #666666;
                line-height: 18px;
                text-align: justify;
                word-break: break-all;
              }
            }
          }
        }
      }
    }
  }
}