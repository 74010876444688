.product_section {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-width: 6.875rem;
  overflow-x: hidden;
  overflow-y: auto;

  .product_box {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}