.scsm_sort_m {
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;

  &>.screen {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 .8533rem;
    height: 1.28rem;

    &>.screen_item {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &>.result {
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    padding: 0 .4267rem;
    &>span {
      font-size: .3733rem;
      font-weight: 400;
      color: #666;
      line-height: .5333rem;
      &>b {
        color: #C90D17;
      }
    }
  }

  &>.list {
    padding: .2667rem .4267rem 0;

    &>.item {
      display: flex;
      flex-direction: column;
      margin-top: .64rem;
      background-color: #FFF;
      border-radius: 2px;
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
      overflow: hidden;

      &>.psm_img {
        width: 100%;
        height: 5.6533rem;
        background-color: #EAEAEA;
        &>img {
          width: 100%;
          object-fit: contain;
        }
      }

      &>.item_box {
        display: flex;
        flex-direction: column;
        padding: .4267rem .4267rem .56rem;

        &>.item_title {
          font-size: .4267rem;
          font-weight: 500;
          color: #171717;
          line-height: .5867rem;
        }

        &>.item_divider {
          margin-top: .1067rem;
          width: .5333rem;
          height: .08rem;
          background-color: #C90D17;
        }

        &>.item_content {
          margin-top: .4267rem;
          font-size: .3467rem;
          font-weight: 400;
          color: #666;
          line-height: .4rem;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 3;
          // overflow: hidden;
          text-align: justify;
        }
      }
    }
  }

  &>.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.1867rem;
  }
}