.yz_exhibition_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 2.9688rem;

  .yz_exhibition_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 6.875rem;
    max-width: 6.875rem;
    height: 100%;

    .yz_exhibition_left {
      display: flex;
      flex-direction: column;
      // width: 50%;
      max-width: 3.1771rem;

      .yz_exhibition_title {
        font-size: .25rem;
        font-weight: 500;
        color: #171717;
        line-height: .349rem;
      }

      .yz_exhibition_vice_title {
        margin-top: .1042rem;
        font-size: 17px;
        font-weight: 400;
        color: #666;
        line-height: 30px;
        text-align: justify;
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .2604rem;
        width: .5938rem;
        height: .25rem;
        background-color: #C90D17;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: rgba(201, 13, 23, 0.8);
        }
  
        span {
          font-size: .0833rem;
          font-weight: 500;
          color: #FFF;
          line-height: .1458rem;
        }
      }
    }

    .yz_exhibition_right1 {
      display: flex;
      justify-content: flex-end;
      padding-right: .3125rem;
      // width: 50%;

      .yz_exhibition_img {
        // width: 100%;
        height: 2.3229rem;
        object-fit: contain;
      }
    }
    .yz_exhibition_right2 {
      display: flex;
      justify-content: flex-start;
      padding-left: .3125rem;
      // width: 50%;

      .yz_exhibition_img {
        // width: 100%;
        height: 2.3229rem;
        object-fit: contain;
      }
    }
  }
  
  .bgc_FAFAFA {
    background-color: #FAFAFA;
  }

  .bgc_FFF {
    background-color: #FFF;
  }
}