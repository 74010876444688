.pdh_footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 194px;
  &>.pdh_footer_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: .6770835rem;
    width: 6.875rem;
    &>.prev,
    &>.next {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 24px 0;
      &>span {
        max-width: 1.5469rem;
        min-width: 1.5469rem;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 19px;
        text-align: justify;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-align: justify;
      }
    }
    &>.prev {
      &>span {
        margin: 0 10px 0 16px;
      }
    }
    &>.next {
      &>span {
        margin: 0 16px 0 10px;
      }
    }
    .opacity {
      opacity: 0.5;
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 152px;
      height: 47px;
      border: 2px solid #333;
      border-radius: 37px;
      user-select: none;
      cursor: pointer;
      &>img {
        width: 20px;
        height: 20px;
      }
      &>span {
        margin-left: 4px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 19px;
      }
    }
    .btn_next {
      &>span {
        margin: 0;
        margin-right: 4px;
      }
    }
  }
}