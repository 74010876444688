.aub_company_m {
  display: flex;
  flex-direction: column;
  padding-top: .8533rem;
  background-color: #FAFAFA;
  overflow: hidden;

  &>.title {
    font-size: .64rem;
    font-weight: 500;
    color: #171717;
    line-height: .9067rem;
    padding: 0 .4267rem .2133rem;
  }

  &>.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 .266666rem;
    &>.item {
      display: flex;
      margin-top: .32rem;
      padding: 0 .16rem;
      width: 50%;
      &>.item_box {
        display: flex;
        flex-direction: row;
        padding: .32rem;
        width: 100%;
        background-color: #F5F5F5;
        &>.spot {
          margin-top: .16rem;
          width: .24rem;
          height: .24rem;
          background-color: #C90D17;
          border-radius: 50%;
        }
        &>.content {
          display: flex;
          flex-direction: column;
          margin-left: .24rem;
          &>span:first-child {
            font-size: .4267rem;
            font-weight: 500;
            color: #171717;
            line-height: .5867rem;
          }
          &>span:last-child {
            margin-top: .1067rem;
            font-size: .3467rem;
            font-weight: 400;
            color: #363636;
            line-height: .48rem;
          }
        }
      }
    }
  }

  &>.map {
    padding: 0 .4267rem;
    &>img {
      width: 100%;
    }
  }
}