.home_contact_us {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  overflow: hidden;

  .main_box {
    flex: 1;
    width: 6.875rem;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: .4167rem;

      span:nth-child(1) {
        font-size: .25rem;
        font-weight: 500;
        color: #C90D17;
        line-height: .3542rem;
      }

      span:nth-child(2) {
        font-size: .125rem;
        font-weight: 400;
        color: #999999;
        line-height: .1771rem;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: .4688rem;
      margin-bottom: .5521rem;
      max-width: 6.875rem;
      min-width: 6.875rem;

      .left {
        width: 3.7188rem;
        .row {
          display: flex;
          flex-direction: row;
          &.margin_top {
            margin-top: 44px;
          }
          &>span:first-child {
            min-width: 106px;
            font-size: 16px;
            font-weight: 400;
            color: #696969;
            line-height: 19px;
          }
          &>span:last-child {
            flex: 1;
            font-size: 18px;
            font-weight: 500;
            color: #171717;
            line-height: 21px;
          }
        }
      }
      .right {
        margin-left: .3646rem;
        width: 2.7917rem;
        .input {
          display: flex;
          flex-direction: row;
          margin-bottom: 24px;
          padding-bottom: 11px;
          border-bottom: 1px solid #EAEAEA;
          &>span {
            min-width: 78px;
            font-size: 16px;
            font-weight: 400;
            color: #696969;
            line-height: 19px;
          }
          &>input {
            margin: 0;
            width: 100%;
            height: 100%;
            font-size: 14px;
            font-weight: 400;
            color: #171717;
            line-height: 16px;
            border: none;
            background-color: transparent;
            outline: none;
    
            &::placeholder {
              font-size: 14px;
              font-weight: 400;
              color: #999;
              line-height: 16px;
            }
          }
        }
        &>.input_code {
          display: flex;
          flex-direction: row;
          margin-bottom: 24px;
          .input {
            margin-bottom: 0;
          }
          .btn_code {
            margin-bottom: 1px;
            width: 105px;
            height: 32px;
            background-color: #FF7777;
            &>img {
              width: 100%;
              height: 100%;
            }
          }
        }
        &>.btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 144px;
          height: 48px;
          border: 2px solid #C90D17;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            background-color: #C90D17;
            &>span {
              color: #FFF;
            }
          }
          &>span {
            font-size: 16px;
            font-weight: 500;
            color: #C90D17;
            line-height: 27px;
          }
        }
      }
    }
  }
}