.sc_sort {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;

  &>.sc_sort_screen {
    display: flex;
    flex-direction: column;
    margin-top: .2083rem;
    margin-bottom: .1042rem;

    .margin_top {
      margin-top: .0833rem;
    }
  }

  &>.sc_sort_result {
    max-width: 6.875rem;
    min-width: 6.875rem;
    &>span {
      font-size: .0729rem;
      font-weight: 400;
      color: #666;
      line-height: .1042rem;
      &>b {
        color: #C90D17;
      }
    }
  }

  &>.sc_sort_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: .2813rem;
    max-width: 7.0416666rem;
    min-width: 7.0416666rem;
    min-height: calc(100vh - 7.1614585rem);

    &>.sc_sort_item {
      display: flex;
      padding: 0 .078125rem .28125rem;
      width: 33.3333%;
      height: max-content;

      .sc_sort_item_box {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        // height: 2.3854rem;
        background-color: #FFF;
        border: 1px solid #FFF;
        box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.03);
        border-radius: 8px;
        overflow: hidden;

        &:hover {
          box-shadow: 0 0 8px -2px #C90D17;
        }

        .sc_sort_img {
          min-height: 1.3542rem;
          height: 1.3542rem;
          object-fit: contain;
        }

        .sc_sort_box {
          display: flex;
          flex-direction: column;
          padding: .1042rem;
          border-top: 1px solid #FAFAFA;

          .sc_sort_title {
            font-size: .1042rem;
            font-weight: 500;
            color: #171717;
            line-height: .1458rem;
          }

          .sc_sort_divider {
            margin-top: .0417rem;
            width: .1563rem;
            height: .0208rem;
            background-color: #C90D17;
          }

          .sc_sort_content {
            margin-top: .0833rem;
            height: 110px;
            font-size: 14px;
            font-weight: 400;
            color: #999;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
            text-align: justify;
          }
        }
      }
    }
  }

  .sc_sort_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: .7292rem;

    // &>.ant-pagination {
    //   font-size: .0833rem;

    //   li.ant-pagination-item:hover {
    //     border: 1px solid #C90D17;
    //     box-sizing: border-box;

    //     a {
    //       color: #C90D17;
    //     }
    //   }

    //   .ant-pagination-prev .ant-pagination-item-link,
    //   .ant-pagination-next .ant-pagination-item-link {
    //     background-color: #FAFAFA;
    //     border: 1px solid #EAEAEA;
    //   }

    //   .ant-pagination-item-link:hover {
    //     border: 1px solid #C90D17;
    //     box-sizing: border-box;

    //     span {
    //       color: #C90D17;
    //     }
    //   }

    //   .ant-pagination-item {
    //     background-color: #FAFAFA;

    //     a {
    //       color: #666;
    //     }
    //   }

    //   .ant-pagination-item-active {
    //     background-color: #C90D17;
    //     border-color: #C90D17;

    //     a {
    //       color: #FFF !important;
    //     }
    //   }

    //   .ant-pagination-options {
    //     .ant-pagination-options-quick-jumper {
    //       margin: 0;
    //       color: #666;

    //       input {
    //         width: 62px;
    //         border: 1px solid #EAEAEA;
    //       }

    //       input:focus {
    //         border: 1px solid #C90D17;
    //       }
    //     }
    //   }
    // }
  }
}