.home_partners {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FFF;

  .main_box {

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: .4167rem;

      span:nth-child(1) {
        font-size: .25rem;
        font-weight: 500;
        color: #C90D17;
        line-height: .3542rem;
      }

      span:nth-child(2) {
        font-size: .125rem;
        font-weight: 400;
        color: #999999;
        line-height: .1771rem;
      }

      span:nth-child(3) {
        margin-top: .2083rem;
        max-width: 4.7292rem !important;
        font-size: 15px;
        font-weight: 400;
        color: #666;
        line-height: 27px;
        max-width: 6.875rem;
        text-align: center;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: .3125rem;
      margin-bottom: .4115rem;

      .partners_list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-width: 6.875rem;
        min-width: 6.875rem;

        .partners_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: .1667rem;
          width: 1.5938rem;
          &.margin_left {
            margin-left: 0;
          }
          &>span {
            font-size: 24px;
            font-weight: 500;
            color: #171717;
            line-height: 38px;
          }
          &>.divider {
            margin-top: .0313rem;
            width: .2604rem;
            height: 4px;
            background-color: #C90D17;
          }
          &>.partners_item_imgs {
            display: flex;
            flex-direction: column;
            justify-content: center;
            &>.partners_img {
              margin-top: 24px;
              width: 200px;
              height: 80px;
              border: 1px solid #EAEAEA;
            }
          }
        }
      }
    }
  }
}