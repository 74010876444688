.home_honor_m {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .8533rem .4267rem;
  background-color: #FFF;
  overflow: hidden;

  &>.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: .533333rem;

    span:nth-child(1) {
      font-size: .64rem;
      font-weight: 500;
      color: #C90D17;
      line-height: .9067rem;
    }

    span:nth-child(2) {
      margin-top: .0533rem;
      font-size: .32rem;
      font-weight: 700;
      color: #999999;
      line-height: .4533rem;
    }
  }
  &>.content {
    padding-bottom: .533333rem;
    font-size: .3467rem;
    font-weight: 400;
    color: #696969;
    line-height: .5867rem;
    text-align: justify;
  }
  
  &>.icon_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .icon_item {
      padding-top: .1667rem;
      width: 50%;

      .icon_box {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon_biaoji {
          min-width: .1333rem;
          width: .1333rem;
          height: .1333rem;
          background-color: #C90D17;
          border-radius: 50%;
        }
        .icon_title {
          margin-left: .2133rem;
          font-size: .3467rem;
          font-weight: 500;
          color: #171717;
          line-height: .48rem;
        }
      }
    }
  }

  &>.btn {
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .8rem;
    padding: .0533rem .64rem;
    background-color: #C90D17;
    border-radius: 4px;
    cursor: pointer;
    &:active {
      background-color: rgba(201, 13, 23, 0.8);
    }
    &>span {
      font-size: .3733rem;
      font-weight: 400;
      color: #FFF;
      line-height: .72rem;
    }
  }

  &>.pic {
    display: flex;
    justify-content: center;
    margin-top: .5333rem;
    &>img {
      width: 7.7333rem;
      height: 5.8133rem;
      object-fit: cover;
    }
  }
}