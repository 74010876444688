.aub_team {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  overflow: hidden;

  &>.main_box {
    flex: 1;
    width: 6.875rem;

    &>.content {
      display: flex;
      flex-direction: row;
      padding: .4167rem 0;

      &>.information {
        display: flex;
        flex-direction: column;
        width: 100%;

        &>.title {
          font-size: .25rem;
          font-weight: 500;
          color: #171717;
          line-height: .2917rem;
        }
        &>.list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          &>.item {
            display: flex;
            flex-direction: column;
            margin-top: .2604rem;
            padding: 0 .0833rem;
            width: 25%;
            // height: 100%;
            &>.item_box {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              height: 100%;
              background-color: #FFF;
              border-radius: 2px;
              box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.04);
              &>img {
                width: 100%;
                height: 1.5938rem;
                object-fit: cover;
              }
              &>.item_content {
                display: flex;
                flex-direction: column;
                padding: .1042rem;
                width: 100%;
                // height: 100%;
                &>.biaoji {
                  margin-top: .0833rem;
                  width: .0938rem;
                  height: 4px;
                  background-color: #C90D17;
                }
                &>span:nth-child(1) {
                  font-size: 20px;
                  font-weight: 500;
                  color: #171717;
                  line-height: 23px;
                }
                &>span:nth-child(2) {
                  max-height: 52px;
                  min-height: 52px;
                  margin-top: .0833rem;
                  font-size: 16px;
                  font-weight: 400;
                  color: #666666;
                  line-height: 26px;
                  word-break: break-all;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-align: justify;
                }
              }
            }
          }
        }
      }
    }
  }
}