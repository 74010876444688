.pd_buoy_box_m {
  position: fixed;
  right: .266666rem;
  bottom: .64rem;
  display: flex;
  flex-direction: column;
  width: 1.333333rem;
  background-color: transparent;
  z-index: 998;

  &>.btn_box {
    display: flex;
    flex-direction: column;
    width: 1.333333rem;
    background-color: #FFF;
    border-radius: 100px;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
    &>.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.333333rem;
      &>img {
        min-width: .64rem;
        min-height: .64rem;
        width: .64rem;
        height: .64rem;
      }
    }
    .btn_wx {
      &:hover > .code {
        display: flex;
      }
      &>.code {
        display: none;
        position: absolute;
        bottom: 0;
        right: 100%;
        padding-right: .4rem;
        user-select: none;
        cursor: pointer;
        &>.code_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: .2667rem;
          background-color: #FFF;
          box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
          &>img {
            margin-bottom: .1333rem;
            width: 3.7333rem;
            height: 3.7333rem;
          }
          &>canvas {
            margin-bottom: .1333rem;
            width: 3.7333rem !important;
            height: 3.7333rem !important;
          }
          &>span {
            font-size: .4267rem;
            font-weight: 400;
            color: #333333;
            line-height: .64rem;
          }
        }
      }
    }
  }
  .btn_to_top_m {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.333333rem;
    height: 1.333333rem;
    background-color: #FFF;
    cursor: pointer;
    margin-top: .32rem;
    border-radius: 50%;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
    &>img {
      min-width: .64rem;
      min-height: .64rem;
      width: .64rem;
      height: .64rem;
    }
  }
}