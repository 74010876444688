.search_sort {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;

  .search_sort_label {
    display: flex;
    padding-top: .2083rem;
    max-width: 7.0416666rem;
    min-width: 7.0416666rem;
    font-size: .1042rem;
    font-weight: 400;
    color: #171717;
    line-height: .1458rem;
  }

  .search_sort_list {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 7.0416666rem;
    min-width: 7.0416666rem;
    // min-height: calc(100vh - 2.6615rem);

    .search_sort_item {
      display: flex;
      padding: .2083rem;
      width: 100%;
      height: max-content;
      cursor: pointer;
      &:hover {
        background-color: #F5F6F7;
      }

      .search_sort_item_box {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: .9375rem;

        &>img {
          width: 1.7188rem;
          height: .7813rem;
          object-fit: contain;
        }

        .search_box {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: 24px;

          .search_title {
            font-size: 18px;
            font-weight: 600;
            color: #171717;
            line-height: 21px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-align: justify;
          }

          .search_content {
            margin-top: .0938rem;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #666;
            line-height: 18px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-align: justify;
          }

          .search_btn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: .0938rem;
            .left {
              display: flex;
              flex-direction: row;
              align-items: center;
              &>img {
                width: 18px;
                height: 18px;
              }
              &>span {
                margin-left: .0313rem;
                font-size: 14px;
                font-weight: 400;
                color: #999;
                line-height: 16px;
              }
            }
            &>.btn {
              display: flex;
              justify-content: center;
              align-items: center;
              width: .75rem;
              height: .25rem;
              background-color: #FAFAFA;
              border: 1px solid #C90D17;
              border-radius: 4px;
              cursor: pointer;
      
              span {
                font-size: .0833333rem;
                font-weight: 500;
                color: #C90D17;
                line-height: .140625rem;
              }
              &:hover {
                background-color: #C90D17;
                &>span {
                  color: #FFF;
                }
              }
            }
          }
          
        }
      }
    }
  }

  .search_sort_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: .7292rem;
  }
}