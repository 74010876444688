.web_navigation_main {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #FAFAFA;

  &>.box_main {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: .2604166rem 0;
    max-width: 6.875rem;
    min-width: 6.875rem;
    &>.web_navigation_aside {
      position: sticky;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      min-width: 1.1458333rem;
      padding-right: .1041666rem;
      width: 1.1458333rem;
      height: 3.25rem;
      background-color: #FAFAFA;
      user-select: none;
      overflow-y: auto;
      z-index: 999;

      &>.aside_item {
        display: flex;
        flex-direction: column;
        width: 100%;

        &>.aside_item_box {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: .15625rem;
          width: 100%;
          height: .3229166rem;
          cursor: pointer;

          &:hover {
            background-color: #ECF5FF;
          }

          &:hover>span {
            color: #C90D17;
          }

          &.active {
            background-color: #ECF5FF;
          }

          &.active>span {
            color: #C90D17;
          }

          &>img {
            width: .1041666rem;
            height: .1041666rem;
          }

          &>span {
            margin-left: .0520835rem;
            font-size: .0833333rem;
            font-weight: 400;
            color: #171717;
            line-height: .1041666rem;
          }
        }

        &>.aside_item_box_list {
          display: flex;
          flex-direction: column;
          padding-left: .15625rem;
          width: 100%;
          height: 0;
          background-color: rgba(255, 255, 255, .5);
          transition: all 500ms;
          overflow: hidden;

          &>.aside_item_box_list_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: .15625rem;
            width: 100%;
            min-height: .2083333rem;
            height: .2083333rem;
            font-size: .0729166rem;
            font-weight: 400;
            color: #171717;
            line-height: .1041666rem;
            cursor: pointer;

            &:hover {
              background-color: #ECF5FF;
            }

            &.margin_top {
              margin-top: .0520835rem;
            }

            &:hover {
              color: #C90D17;
            }
          }
        }
      }
    }

    &>.web_navigation_article {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: .4427085rem;

      &>.web_navigation_article_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: .078125rem;
        padding-bottom: .1875rem;

        &>.web_navigation_article_header_left,
        &>.web_navigation_article_header_right {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .btn {
          padding: .03125rem .0833333rem;
          border-radius: 4px;
          border: 1px solid #CCCCCC;
          font-size: .0833333rem;
          font-weight: 400;
          color: #171717;
          line-height: .1458333rem;
          user-select: none;
          cursor: pointer;

          &.margin_left {
            margin-left: .0625rem;
          }

          &.btn_add {
            background-color: #C90D17;
            border: 1px solid #C90D17;
            color: #FFF;
          }

          &.btn_save {
            background-color: #FF4D4F;
            border: 1px solid #FF4D4F;
            color: #FFF;
          }
        }
      }

      &>.web_navigation_article_box {
        display: flex;
        flex-direction: column;
        border: 1px dashed transparent;
        border-radius: 8px;
        box-sizing: border-box;

        &.active {
          border: 1px dashed #C90D17;
          // box-shadow: 0 0 16px -2px #C90D17;
        }

        &>.web_navigation_article_box_title {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: .078125rem;
          margin-bottom: .125rem;
          height: .1302085rem;
          user-select: none;

          &>.divider {
            width: 4px;
            height: 18px;
            background-color: #C90D17;
          }

          &>span {
            margin-left: .0520833rem;
            font-size: 18px;
            font-weight: 400;
            line-height: 18px;
            color: #171717;
          }

          &>.icons {
            padding: 0 .0520833rem;
            user-select: none;
            cursor: pointer;
            &>img {
              width: 16px;
              height: 16px;
            }
          }
          
        }

        &>.web_navigation_article_box_list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          &>.web_navigation_article_box_list_item {
            padding: 0 .078125rem;
            width: 33.3333%;

            &>.web_navigation_article_box_list_item_box {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-bottom: .15625rem;
              padding: 16px;
              width: 100%;
              background-color: #FFF;
              border: 1px solid #EAEAEA;
              border-radius: 8px;
              box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.03);
              cursor: pointer;

              &.doudong {
                animation: doudong 1s infinite;
              }

              &.active {
                border: 1px dashed #C90D17;
                box-shadow: 0 0 16px -2px #C90D17;
              }

              // &:hover {
              //   border: 1px solid #C90D17;
              //   box-shadow: 0 0 8px 2px #C90D17;
              // }

              &>.box_img {
                width: .1354165rem;
                height: .1354165rem;
                user-select: none;
              }

              &>span {
                margin-left: .0625rem;
                font-size: 18px;
                font-weight: 500;
                color: #171717;
                line-height: 18px;
              }

              &>.p_img {
                position: absolute;
                top: 8px;
                right: 8px;
                width: 16px;
                height: 16px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .web_navigation_article_box2 {
        display: flex;
        flex-direction: column;

        &>.web_navigation_article_box_title {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: .078125rem;
          margin-bottom: .125rem;
          height: .1302085rem;
          user-select: none;

          &.margin_top {
            margin-top: .15625rem;
          }

          &>.divider {
            width: 4px;
            height: 18px;
            background-color: #C90D17;
          }

          &>span {
            margin-left: .0520833rem;
            font-size: 18px;
            font-weight: 400;
            line-height: 18px;
            color: #171717;
          }
        }

        &>.web_navigation_article_box_list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          &>.web_navigation_article_box_list_item {
            padding: 0 .078125rem;
            width: 33.3333%;

            &>.web_navigation_article_box_list_item_box {
              display: flex;
              flex-direction: row;
              margin-bottom: .15625rem;
              padding: 16px;
              width: 100%;
              background-color: #FFF;
              border: 1px solid #EAEAEA;
              border-radius: 8px;
              box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.03);
              cursor: pointer;

              // &:hover {
              //   border: 1px solid #C90D17;
              //   box-shadow: 0 0 8px 2px #C90D17;
              // }
              &>img {
                width: .2604165rem;
                height: .2604165rem;
                user-select: none;
              }

              &>.web_navigation_article_box_list_item_box_right {
                display: flex;
                flex-direction: column;
                margin-left: .0625rem;

                &>span:nth-child(1) {
                  font-size: 18px;
                  font-weight: 500;
                  color: #171717;
                  line-height: 25px;
                }

                &>span:nth-child(2) {
                  margin-top: .0208333rem;
                  height: 66px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #666;
                  line-height: 22px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                  overflow: hidden;
                  text-align: justify;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes doudong {

  0%,
  100% {
    transform: rotateZ(0.75deg);
  }

  25%,
  75% {
    transform: rotateZ(-0.75deg);
  }

  50% {
    transform: rotateZ(0.75deg);
  }
}