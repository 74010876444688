.home_contact_us_m {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem;
  background-color: #FFF;

  &>.title {
    display: flex;
    flex-direction: column;
    align-items: center;

    span:nth-child(1) {
      font-size: .64rem;
      font-weight: 500;
      color: #C90D17;
      line-height: .9067rem;
    }

    span:nth-child(2) {
      margin-top: .0533rem;
      font-size: .32rem;
      font-weight: 700;
      color: #999999;
      line-height: .4533rem;
    }
  }

  &>.contact_us_list {
    display: flex;
    flex-direction: column;
    &>.row {
      display: flex;
      flex-direction: row;
      margin-top: .32rem;
      &.margin_top {
        margin-top: .853333rem;
      }
      &>span:first-child {
        width: 2.1867rem;
        font-size: .3467rem;
        font-weight: 400;
        color: #666;
        line-height: .56rem;
      }
      &>span:last-child {
        flex: 1;
        font-size: .4rem;
        font-weight: 500;
        color: #171717;
        line-height: .56rem;
        text-align: justify;
      }
    }
  }

  &>.contact_us_form {
    display: flex;
    flex-direction: column;
    .input {
      display: flex;
      flex-direction: row;
      margin-top: .426666rem;
      padding-bottom: .266666rem;
      border-bottom: 1px solid #EAEAEA;
      &>span {
        min-width: 1.813333rem;
        font-size: .346666rem;
        font-weight: 400;
        color: #696969;
        line-height: .48rem;
      }
      &>input {
        margin: 0;
        width: 100%;
        height: 100%;
        font-size: .346666rem;
        font-weight: 400;
        color: #171717;
        line-height: .48rem;
        border: none;
        background-color: transparent;
        outline: none;

        &::placeholder {
          font-size: .346666rem;
          font-weight: 400;
          color: #999;
          line-height: .48rem;
        }
      }
    }
    &>.input_code {
      display: flex;
      flex-direction: row;
      .input {
        width: 4.48rem;
      }
      .btn_code {
        align-self: flex-end;
        display: flex;
        margin-left: .213333rem;
        width: 2.8rem;
        height: .853333rem;
        &>img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &>.textarea {
      display: flex;
      flex-direction: row;
      margin-top: .426666rem;
      padding-bottom: .266666rem;
      border-bottom: 1px solid #EAEAEA;
      &>span {
        min-width: 1.813333rem;
        font-size: .346666rem;
        font-weight: 400;
        color: #696969;
        line-height: .48rem;
      }
      &>textarea {
        margin: 0;
        width: 100%;
        height: 100%;
        font-size: .346666rem;
        font-weight: 400;
        color: #171717;
        line-height: .48rem;
        border: none;
        background-color: transparent;
        outline: none;

        &::placeholder {
          font-size: .346666rem;
          font-weight: 400;
          color: #999;
          line-height: .48rem;
        }
      }
    }
  }

  &>.btn {
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .8rem;
    padding: .0533rem .64rem;
    width: max-content;
    border: 1px solid #C90D17;
    border-radius: 4px;
    cursor: pointer;
    &:active {
      background-color: #C90D17;
      &>span {
        color: #FFF;
      }
    }
    span {
      font-size: .3733rem;
      font-weight: 400;
      color: #C90D17;
      line-height: .72rem;
    }
  }
}