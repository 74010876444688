.yz_exhibition_row_m {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: .8533rem .4267rem .9067rem;


  &>img {
    width: 3.0667rem;
    height: 3.0667rem;
    object-fit: contain;
  }

  &>.yz_content {
    display: flex;
    flex-direction: column;
    margin-left: .2667rem;

    &>.yz_exhibition_title {
      margin-top: .08rem;
      font-size: .48rem;
      font-weight: 500;
      color: #171717;
      line-height: .6667rem;
    }

    &>.yz_exhibition_vice_title {
      margin-top: .16rem;
      font-size: .32rem;
      font-weight: 400;
      color: #666;
      line-height: .3733rem;
      text-align: justify;
    }

    &>.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: .32rem;
      width: 2.7733rem;
      height: .8267rem;
      background-color: #C90D17;
      border-radius: 4px;
      cursor: pointer;
      &:active {
        background-color: rgba(201, 13, 23, 0.8);
      }

      &>span {
        font-size: .3733rem;
        font-weight: 400;
        color: #FFF;
        line-height: .72rem;
      }
    }
  }

  &>.bgc_FAFAFA {
    background-color: #FAFAFA;
  }

  &>.bgc_FFF {
    background-color: #FFF;
  }
}