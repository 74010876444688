.yz_sort_row_list {
  display: flex;
  flex-direction: row;
  min-width: 6.875rem;
  max-width: 6.875rem;

  span {
    display: flex;
    align-items: center;
    height: .25rem;
    font-size: .0729rem;
    font-weight: 400;
    color: #171717;
    line-height: .1042rem;
    white-space: nowrap;
  }

  .yz_sort_row_list_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .yz_sort_row_list_item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: .0417rem;
      padding: 0 .1042rem;
      height: .25rem;
      font-size: .0729rem;
      font-weight: 400;
      color: #171717;
      line-height: .1042rem;
      white-space: nowrap;
      cursor: pointer;
    }

    .yz_sort_row_list_item_active {
      border-radius: 58px;
      background-color: #C90D17;
      color: #FFF;
    }
  }
}