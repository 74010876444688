.pdb_buoy_box {
  position: sticky;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: .6771rem;
  height: 100%;
  &>.btn {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    &.btn_wb {
      cursor: pointer;
    }
    &>img {
      width: 50px;
      height: 50px;
    }
    &:hover > .code {
      display: flex;
    }
    &>.code {
      display: none;
      position: absolute;
      top: 7px;
      left: 100%;
      padding-left: 15px;
      user-select: none;
      cursor: pointer;
      &>.code_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        &>img {
          margin-bottom: 5px;
          width: 140px;
          height: 140px;
        }
        &>canvas {
          margin-bottom: 5px;
          width: 140px !important;
          height: 140px !important;
        }
        &>span {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }
      }
    }
  }
}