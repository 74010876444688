.aub_advantage_m {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem;
  background-color: #FAFAFA;
  overflow: hidden;

  &>.title {
    font-size: .64rem;
    font-weight: 500;
    color: #171717;
    line-height: .9067rem;
  }

  &>.tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: .5333rem;
    &>.tabs_item {
      display: flex;
      padding-bottom: .6933rem;
      width: 33.3333%;
      &>.tabs_item_box {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: .2133rem 0;
        width: 100%;
        background-color: #FFF;
        font-size: .4rem;
        font-weight: 500;
        color: #171717;
        line-height: .56rem;
        &>.to_bottom_san {
          position: absolute;
          display: none;
          top: 100%;
          left: 50%;
          width: 0;
          height: 0;
          transform: translateX(-50%);
          border: .2667rem solid transparent;
          border-top: .2667rem solid  #C90D17;
        }
      }
      &.active {
        &>.tabs_item_box {
          background-color: #C90D17;
          font-weight: 700;
          color: #FFF;
          &>.to_bottom_san {
            display: block;
          }
        }
      }
    }
  }

  &>.content {
    font-size: .3733rem;
    font-weight: 400;
    color: #696969;
    line-height: .5333rem;
    text-align: justify;
    white-space: pre-wrap;
  }

  &>.list1 {
    display: flex;
    flex-direction: column;
    margin-top: .4267rem;
    padding: 0 .32rem .5333rem;
    background-color: #F5F5F5;
    &>.item {
      display: flex;
      flex-direction: row;
      margin-top: .5333rem;
      &>.spot {
        margin-top: .1333rem;
        min-width: .2667rem;
        width: .2667rem;
        height: .2667rem;
        background-color: #C90D17;
        border-radius: 50%;
      }
      &>span {
        margin-left: 8px;
        font-size: .3733rem;
        font-weight: 400;
        color: #171717;
        line-height: .5333rem;
        text-align: justify;
      }
    }
  }

  &>.list2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: .2133rem;
    &>.item {
      display: flex;
      margin-top: .2133rem;
      padding: 0 .1867rem;
      width: 33.3333%;
      &>.item_box {
        display: flex;
        width: 100%;
        background-color: #F5F5F5;
        &>img {
          width: 100%;
          height: 1.0667rem;
          object-fit: cover;
        }
      }
    }
    
  }
}