.about_us_introduce_m {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem;
  background-color: #FFF;

  &>.title {
    font-size: .64rem;
    font-weight: 500;
    color: #171717;
    line-height: .9067rem;
  }

  &>.content {
    margin-top: .2133rem;
    font-size: .3733rem;
    font-weight: 400;
    color: #666;
    line-height: .5867rem;
    text-align: justify;
  }

  &>.icon_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: .1067rem;

    &>.icon_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: .5333rem;
      width: 50%;
      height: 1.1733rem;

      .icon_img {
        width: 1.1733rem;
        height: 1.1733rem;
      }

      .icon_box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: .32rem;
        height: 1.1733rem;

        .icon_title {
          font-size: .56rem;
          font-weight: 600;
          color: #C90D17;
          line-height: .56rem;
        }

        .icon_content {
          font-size: .32rem;
          font-weight: 400;
          color: #666;
          line-height: .4533rem;
        }
      }
    }
  }
}