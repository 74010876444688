* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* >=540 */
@media screen and (min-width: 540px) {
  html {
    font-size: 128px !important;
    /*no*/
  }
}
// /* >=800 */
// @media screen and (min-width: 800px) {
//   html {
//     font-size: 80px !important;
//     /*no*/
//   }
// }
// /* >=1024 */
// @media screen and (min-width: 1024px) {
//   html {
//     font-size: 102.4px !important;
//     /*no*/
//   }
// }
/* >=1280 */
@media screen and (min-width: 1280px) {
  html {
    font-size: 128px !important;
    /*no*/
  }
}
/* >=1366 */
@media screen and (min-width: 1366px) {
  html {
    font-size: 136.6px !important;
    /*no*/
  }
}
/* >=1440 */
@media screen and (min-width: 1440px) {
  html {
    font-size: 144px !important;
    /*no*/
  }
}
/* >=1600 */
@media screen and (min-width: 1600px) {
  html {
    font-size: 160px !important;
    /*no*/
  }
}
/* >=1700 */
@media screen and (min-width: 1700px) {
  html {
    font-size: 170px !important;
    /*no*/
  }
}
/* >=1800 */
@media screen and (min-width: 1800px) {
  html {
    font-size: 180px !important;
    /*no*/
  }
}
/* >=1920 */
@media screen and (min-width: 1920px) {
  html {
    font-size: 192px !important;
    /*no*/
  }
}