.aub_team_m {
  display: flex;
  flex-direction: column;
  padding: .8533rem 0;
  background-color: #FFF;
  overflow: hidden;

  &>.title {
    font-size: .64rem;
    font-weight: 500;
    color: #171717;
    line-height: .9067rem;
    padding: 0 .4267rem;
  }

  &>.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 .266666rem;
    &>.item {
      display: flex;
      margin-top: .32rem;
      padding: 0 .16rem;
      width: 50%;
      &>.item_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #FFF;
        border-radius: 2px;
        box-shadow: 0 3px 12px 0 rgba(0,0,0,0.04);
        overflow: hidden;
        &>img {
          width: 100%;
          height: 4.4267rem;
          object-fit: cover;
        }
        &>.content {
          display: flex;
          flex-direction: column;
          padding: .32rem;
          &>.title {
            font-size: .4267rem;
            font-weight: 500;
            color: #171717;
            line-height: .5867rem;
          }
          &>.jianjie {
            margin-top: .2133rem;
            max-height: 1.44rem;
            min-height: 1.44rem;
            font-size: .3467rem;
            font-weight: 400;
            color: #696969;
            line-height: .48rem;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-align: justify;
          }
          &>.divider {
            margin-top: .2133rem;
            width: .48rem;
            min-height: 4px;
            height: 4px;
            background-color: #C90D17;
          }
        }
      }
    }
  }
}