.cds_section_m {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-y: auto;

  .cds_box_m {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}