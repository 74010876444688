.pdsm_section {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-y: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  background-color: transparent;

  .pdsm_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    background-color: transparent;
  }
}