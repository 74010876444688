.scrolling_text_box {
  display: flex;
  flex-direction: row;

  &>.box_item {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: .125rem;
    height: .21875rem;
    font-size: .1875rem;
    font-weight: bold;
    color: #C90D17;
    line-height: .21875rem;
    text-align: center;
    position: relative;
    writing-mode: vertical-lr;
    text-orientation: upright;
    overflow: hidden;
    cursor: default;

    &>span {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      letter-spacing: .0625rem;
      transition: transform 400ms ease-in-out;
    }
  }

  &>.default_title {
    font-size: .1875rem;
    font-weight: bold;
    color: #C90D17;
    line-height: .21875rem;
    cursor: default;
  }
}