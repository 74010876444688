.pdh_footer_m {
  display: flex;
  flex-direction: column;
  padding: 0 .4267rem .853333rem;
  &>.pdh_footer_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    &>.prev,
    &>.next {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: .533333rem;
      width: 100%;
      &>span {
        flex: 1;
        margin: 0 .266666rem 0 .426666rem;
        font-size: .373333rem;
        font-weight: 400;
        color: #333;
        line-height: .48rem;
        text-align: justify;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-align: justify;
      }
    }
    .opacity {
      opacity: 0.5;
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .1067rem .64rem;
      min-width: max-content;
      border: 2px solid #333;
      border-radius: 37px;
      user-select: none;
      cursor: pointer;
      &>img {
        width: .5333rem;
        height: .5333rem;
      }
      &>span {
        margin-left: .106666rem;
        font-size: .3467rem;
        font-weight: 500;
        color: #333333;
        line-height: .72rem;
      }
    }
    .btn_next {
      &>span {
        margin: 0;
        margin-right: .106666rem;
      }
    }
  }
}