.about_course_m {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem 1.0667rem;
  // height: 15.8933rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &>.title {
    font-size: .64rem;
    font-weight: 500;
    color: #171717;
    line-height: .9067rem;
  }

  &>.content {
    margin-top: .2133rem;
    font-size: .3733rem;
    font-weight: 400;
    color: #666;
    line-height: .5867rem;
    text-align: justify;
  }

  &>.icon_list {
    margin-top: .48rem;
    &>img {
      width: 100%;
    }
  }
}