.footer_m {
  display: flex;
  flex-direction: column;
  background-color: #C90D17;

  &>.m_main {
    display: flex;
    flex-direction: column;
    padding: .8533rem .4267rem .5333rem;

    &>.m_about {
      display: flex;
      flex-direction: column;

      &>.m_about_title {
        padding-bottom: .5333rem;
        font-size: .4267rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        line-height: .5867rem;
      }

      &>.m_about_link {
        display: flex;
        flex-direction: row;
        margin-top: .4267rem;

        &>img {
          width: .5333rem;
          height: .5333rem;
        }

        &>span {
          margin-left: .2667rem;
          font-size: .3733rem;
          font-weight: 400;
          color: #FFF;
          line-height: .5333rem;
          text-align: justify;
        }
      }
    }

    &>.m_code {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: .8533rem;
      padding: 0 1.0667rem;

      &>.m_code_item {
        display: flex;
        flex-direction: column;
        align-items: center;

        &>img {
          width: 2.7467rem;
          height: 2.7467rem;
        }

        &>span {
          margin-top: .08rem;
          font-size: .4rem;
          font-weight: 400;
          color: #FFF;
          line-height: .72rem;
        }
      }
    }

    &>.margin_top0 {
      margin-top: 0;
    }
  }

  &>.m_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #B50811;
    height: 1.8667rem;

    &>.m_content1 {
      display: flex;
      flex-direction: row;
      align-items: center;

      &>img {
        width: .5333rem;
        height: .5333rem;
      }

      &>span {
        margin-left: .1067rem;
        font-size: .3467rem;
        font-weight: 400;
        color: #ccc;
        line-height: .48rem;
      }
    }

    &>.m_content2 {
      font-size: .3467rem;
      font-weight: 400;
      color: #ccc;
      line-height: .48rem;
    }
  }
}