.yz_modal_add_web {
  &.ant-modal {
    width: auto !important;

    .ant-modal-content {
      width: auto !important;
      border-radius: 8px;

      .ant-modal-body {
        padding: .125rem;

        &>.modal_header_web {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: .0520835rem;

          &>.modal_title_web {
            font-size: .125rem;
            font-weight: 500;
            color: #000;
            line-height: .1770835rem;
          }

          &>.modal_close_web {
            display: flex;
            justify-content: center;
            align-items: center;
            width: .125rem;
            height: .125rem;

            .modal_close_img_web {
              width: 100%;
              height: 100%;
            }
          }
        }

        &>.modal_body_web {
          display: flex;
          flex-direction: column;
          width: 2.2083333rem;

          &>.web_input_box {
            display: flex;
            flex-direction: column;
            margin-top: .0520835rem;
            padding: .03125rem 0;

            &>span {
              font-size: .0833333rem;
              font-weight: 400;
              color: #171717;
              line-height: .1145835rem;
            }

            &>.web_input_box_main {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: .0416666rem;
              padding: .0520835rem .0833333rem;
              height: .2083333rem;
              border: 0.5px solid #B0B0B0;
              border-radius: 4px;

              &>input {
                margin: 0;
                width: 100%;
                height: 100%;
                font-size: .0729165rem;
                font-weight: 400;
                color: #171717;
                line-height: .0729165rem;
                border: none;
                background-color: transparent;
                outline: none;

                &::placeholder {
                  font-size: .0729165rem;
                  font-weight: 400;
                  color: #999;
                  line-height: .0729165rem;
                }
              }
            }
          }

          &>.web_select_box {
            display: flex;
            flex-direction: column;
            margin-top: .0520835rem;
            padding: .03125rem 0;

            &>span {
              font-size: .0833333rem;
              font-weight: 400;
              color: #171717;
              line-height: .1145835rem;
            }

            &>.web_select_box_main {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: .0416666rem;
              padding: .0520835rem .0833333rem;
              height: .2708333rem;
              border: 0.5px solid #B0B0B0;
              border-radius: 4px;
              cursor: pointer;

              &>.web_select_box_main_content {
                position: absolute;
                left: 0;
                top: 100%;
                padding: .0520833rem 0;
                width: 100%;
                max-height: .78125rem;
                background-color: #FAFAFA;
                border-radius: 0 0 4px 4px;
                transition: all 200ms;
                overflow-y: auto;

                &>.web_select_box_main_content_item {
                  display: flex;
                  align-items: center;
                  padding: 0 .0833333rem;
                  width: 100%;
                  height: .25rem;
                  font-size: .0729165rem;
                  font-weight: 400;
                  color: #171717;
                  line-height: .0729165rem;

                  &.active {
                    background-color: #DDD;
                  }

                  &:hover {
                    background-color: #DDD;
                  }
                }
              }

              &>span {
                width: 100%;
                height: 100%;
                font-size: .0729165rem;
                font-weight: 400;
                color: #999;
                line-height: .1666666rem;
              }

              &>img {
                width: .1666666rem;
                height: .1666666rem;
              }
            }
          }

          &>.web_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: .15625rem;
            width: 100%;
            height: .2291666rem;
            background-color: #C90D17;
            border-radius: 4px;
            cursor: pointer;

            &>span {
              font-size: .0833333rem;
              font-weight: 700;
              color: #FFF;
              line-height: .1041666rem;
            }
          }
        }
      }
    }
  }
}