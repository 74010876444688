.home_swiper_m {
  position: relative;
  display: flex;
  flex-direction: column;

  .swiper_prev {
    position: absolute;
    left: 0;
    top: 50%;
    width: 1.1733rem;
    height: 1.1733rem;
    transform: translateY(-50%);
    z-index: 520;
  }

  .swiper_next {
    position: absolute;
    right: 0;
    top: 50%;
    width: 1.1733rem;
    height: 1.1733rem;
    transform: translateY(-50%);
    z-index: 520;
  }

  .ant-carousel {
    height: 100% !important;

    .slick-slider {
      height: 100% !important;

      .slick-list {
        height: 100% !important;

        .slick-track {
          height: 100% !important;

          .slick-slide {
            height: 100% !important;

            &>div {
              height: 100% !important;

              &>div {
                height: 100% !important;
              }
            }
          }
        }
      }

      .slick-dots.slick-dots-bottom {
        position: absolute;
        right: auto;
        left: 50%;
        bottom: .2667rem;
        margin: 0;
        transform: translate(-50%);

        li {
          width: .5333rem;
          height: .0533rem;
          margin: 0 .16rem;

          &>button {
            width: 100%;
            height: 100%;
            background-color: #FFF;
            opacity: 1;
          }

          &.slick-active {

            &>button {
              width: 100%;
              height: 100%;
              background-color: #C90D17;
            }
          }
        }
      }
    }
  }

  .box {
    position: relative;
    height: 8.8rem;
    background-color: #e5f4ff;

    .swiper_img_bg {
      position: relative;
      padding: .96rem .8rem 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .title {
          font-size: .64rem;
          font-weight: 500;
          color: #171717;
          line-height: .9067rem;
        }
  
        .box_span {
          display: flex;
          flex-direction: column;
          margin-top: .32rem;
  
          &>span {
            font-size: .3467rem;
            color: #333;
            line-height: .48rem;
            text-align: center;
            white-space: pre-wrap;
          }
        }
  
        .btn_consult {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: .64rem;
          width: 2.88rem;
          height: .8533rem;
          background-color: #C90D17;
          border-radius: 4px;
          cursor: pointer;
          &:active {
            background-color: rgba(201, 13, 23, 0.8);
          }
  
          span {
            font-size: .4rem;
            font-weight: 500;
            color: #FFF;
            line-height: .7467rem;
          }
        }
      }
    }
  }
}