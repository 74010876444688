.product_sort {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;

  .product_sort_title {
    margin-top: .625rem;
    margin-bottom: .2083rem;
    font-size: .25rem;
    font-weight: 500;
    color: #171717;
    line-height: .2917rem;
  }

  .product_sort_list {
    display: flex;
    flex-direction: column;
    max-width: 7.0416666rem;
    min-width: 7.0416666rem;
    min-height: calc(100vh - 7.1614585rem);

    .product_sort_item {
      display: flex;
      padding: .2083rem;
      width: 100%;
      height: max-content;
      cursor: pointer;
      &:hover {
        background-color: #F5F6F7;
      }

      .product_sort_item_box {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: .9375rem;

        &>img {
          width: 1.7188rem;
          height: .7813rem;
          object-fit: cover;
        }

        .product_box {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-left: 24px;

          .product_title {
            font-size: 18px;
            font-weight: 600;
            color: #171717;
            line-height: 21px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-align: justify;
          }

          .product_content {
            margin-top: .0938rem;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #666;
            line-height: 18px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-align: justify;
          }

          .product_btn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: .0938rem;
            .left {
              display: flex;
              flex-direction: row;
              align-items: center;
              &>img {
                width: 18px;
                height: 18px;
              }
              &>span {
                margin-left: .0313rem;
                font-size: 14px;
                font-weight: 400;
                color: #999;
                line-height: 16px;
              }
            }
            &>.btn {
              display: flex;
              justify-content: center;
              align-items: center;
              width: .75rem;
              height: .25rem;
              background-color: #FAFAFA;
              border: 1px solid #C90D17;
              border-radius: 4px;
              cursor: pointer;
      
              span {
                font-size: .0833333rem;
                font-weight: 500;
                color: #C90D17;
                line-height: .140625rem;
              }
              &:hover {
                background-color: #C90D17;
                &>span {
                  color: #FFF;
                }
              }
            }
          }
          
        }
      }
    }
  }

  .product_sort_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: .7292rem;

    // &>.ant-pagination {
    //   font-size: .0833rem;

    //   li.ant-pagination-item:hover {
    //     border: 1px solid #C90D17;
    //     box-sizing: border-box;

    //     a {
    //       color: #C90D17;
    //     }
    //   }

    //   .ant-pagination-prev .ant-pagination-item-link,
    //   .ant-pagination-next .ant-pagination-item-link {
    //     background-color: #FAFAFA;
    //     border: 1px solid #EAEAEA;
    //   }

    //   .ant-pagination-item-link:hover {
    //     border: 1px solid #C90D17;
    //     box-sizing: border-box;

    //     span {
    //       color: #C90D17;
    //     }
    //   }

    //   .ant-pagination-item {
    //     background-color: #FAFAFA;

    //     a {
    //       color: #666;
    //     }
    //   }

    //   .ant-pagination-item-active {
    //     background-color: #C90D17;
    //     border-color: #C90D17;

    //     a {
    //       color: #FFF !important;
    //     }
    //   }

    //   .ant-pagination-options {
    //     .ant-pagination-options-quick-jumper {
    //       margin: 0;
    //       color: #666;

    //       input {
    //         width: 62px;
    //         border: 1px solid #EAEAEA;
    //       }

    //       input:focus {
    //         border: 1px solid #C90D17;
    //       }
    //     }
    //   }
    // }
  }
}