.pdh_header_m {
  display: flex;
  flex-direction: row;
  padding: 0 .426666rem;
  width: 100%;
  height: 2.133333rem;

  &>.pdh_header_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    &>span {
      font-size: .373333rem;
      font-weight: 400;
      color: #333;
      line-height: .373333rem;
      user-select: none;
      cursor: pointer;
      &.active {
        color: #C90D17;
      }
    }
    &>.to_right {
      padding: 0 .08rem;
      font-size: .373333rem;
      font-weight: 400;
      color: #333;
      line-height: .373333rem;
    }
  }
}