.home_swiper {
  position: relative;
  // flex: 1;
  display: flex;
  flex-direction: column;

  .swiper_prev {
    position: absolute;
    left: .4167rem;
    top: 50%;
    width: .4167rem;
    height: .4167rem;
    transform: translateY(-50%);
    z-index: 520;
  }

  .swiper_next {
    position: absolute;
    right: .4167rem;
    top: 50%;
    width: .4167rem;
    height: .4167rem;
    transform: translateY(-50%);
    z-index: 520;
  }

  .ant-carousel {
    height: 100% !important;

    .slick-slider {
      height: 100% !important;

      .slick-list {
        height: 100% !important;

        .slick-track {
          height: 100% !important;

          .slick-slide {
            height: 100% !important;

            &>div {
              height: 100% !important;

              &>div {
                height: 100% !important;
              }
            }
          }
        }
      }

      .slick-dots.slick-dots-bottom {
        position: absolute;
        right: auto;
        left: 1.5625rem;
        bottom: .3646rem;
        margin: 0;

        li {
          width: .2292rem;
          height: .026rem;
          margin: 0 .0156rem;

          &>button {
            width: 100%;
            height: 100%;
            background-color: rgba(201, 13, 23, .2);
            opacity: 1;
          }

          &.slick-active {

            &>button {
              width: 100%;
              height: 100%;
              background-color: rgba(201, 13, 23, 1);
            }
          }
        }
      }
    }
  }

  .box {
    position: relative;
    height: 4.5781rem;
    background-color: #e5f4ff;

    .swiper_img_bg {
      position: relative;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      .content {
        position: absolute;
        left: 1.5625rem;
        top: 1.4688rem;
  
        .title {
          font-size: .3021rem;
          font-weight: 500;
          color: #171717;
          line-height: .4167rem;
        }
  
        .box_span {
          display: flex;
          flex-direction: column;
          margin-top: .2083rem;
  
          &>span {
            font-size: .1042rem;
            color: #171717;
            list-style: .1458rem;
            white-space: pre-wrap;
          }
        }
  
        .btn_consult {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: .2292rem;
          width: .7917rem;
          height: .25rem;
          background-color: #C90D17;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            background-color: rgba(201, 13, 23, 0.8);
          }
  
          span {
            font-size: .0833rem;
            font-weight: 500;
            color: #FFF;
            line-height: .1458rem;
          }
        }
      }
    }
  }
}