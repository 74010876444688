.home_honor {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  overflow: hidden;

  .main_box {
    flex: 1;
    width: 6.875rem;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: .4167rem;

      span:nth-child(1) {
        font-size: .25rem;
        font-weight: 500;
        color: #C90D17;
        line-height: .3542rem;
      }

      span:nth-child(2) {
        font-size: .125rem;
        font-weight: 400;
        color: #999999;
        line-height: .1771rem;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      margin-top: .4688rem;
      margin-bottom: .5521rem;

      .map {
        height: max-content;

        .map_img {
          width: 3.5208rem;
          height: 2.651rem;
        }
      }

      .information {
        display: flex;
        flex-direction: column;
        margin-left: .3646rem;

        .label {
          padding: .0938rem 0 .2083rem;
          font-size: 15px;
          font-weight: 400;
          color: #666666;
          line-height: 27px;
          text-align: justify;
        }

        .icon_list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .icon_item {
            padding-top: .1667rem;
            width: 50%;

            .icon_box {
              display: flex;
              flex-direction: row;
              align-items: center;

              .icon_biaoji {
                width: 5px;
                height: 5px;
                background-color: #C90D17;
                border-radius: 50%;
              }
              .icon_title {
                margin-left: .0625rem;
                font-size: 15px;
                font-weight: 700;
                color: #171717;
                line-height: 27px;
              }
            }
          }
        }

        &>.btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: .4688rem;
          width: 144px;
          height: 48px;
          background-color: #C90D17;
          border-radius: 4px;
          cursor: pointer;
          &:hover {
            background-color: rgba(201, 13, 23, 0.8);
          }
  
          span {
            font-size: 16px;
            font-weight: 500;
            color: #FFF;
            line-height: 27px;
          }
        }
      }
    }
  }
}