.header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 .4167rem;
  width: 100%;
  min-height: .46875rem;
  height: .46875rem;
  background-color: #C90D17;
  z-index: 999;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: .46875rem;
    overflow: hidden;

    .logo_img {
      // width: .9583rem;
      height: .3333rem;
      object-fit: contain;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;

    .menu_list {
      display: flex;
      justify-content: center;
      align-items: center;

      .menu_item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: .5417rem;
        height: .46875rem;
        font-size: .0833rem;
        font-weight: 400;
        color: #FFF;
        line-height: .1146rem;
        cursor: default;

        &.menu_active {
          font-weight: 600;
          background-color: rgba(255, 255, 255, 0.1);
        }

        &:hover {
          font-weight: 600;
          background-color: rgba(255, 255, 255, 0.1);
        }
        &:hover > .menu_item_list.active {
          display: flex;
        }
        &>.menu_item_list {
          position: absolute;
          left: 0;
          top: 100%;
          display: none;
          flex-direction: column;
          width: 100%;
          background-color: #FAFAFA;
          &>.menu_item_list_item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: .2708rem;
            font-size: .0729rem;
            font-weight: 400;
            color: #171717;
            line-height: 16px;
            user-select: none;
            cursor: pointer;
            &:hover {
              background-color: #FFF;
            }
          }
        }
      }
    }

    .other {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: .0833rem;

      .oivider {
        width: .0052rem;
        height: .0729rem;
        background-color: #FFF;
      }

      .btn_search {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: .1667rem;
        cursor: pointer;

        .btn_search_img {
          width: .125rem;
          height: .125rem;
        }
      }

      .login,
      .register {
        font-size: .0833rem;
        color: #FFF;
        line-height: .1146rem;
        white-space: nowrap;
        cursor: pointer;
      }
      .login {
        margin-left: .1667rem;
      }
    }
  }

  .search_box {
    position: absolute;
    right: .4167rem;
    bottom: .5208rem;
    display: flex;
    flex-direction: row;
    width: 1.875rem;
    height: .2604rem;
    background-color: #FAFAFA;
    box-shadow: 0px 6px 18px 0px rgba(0,0,0,0.03);
    transition: bottom 300ms;
    opacity: 0.5;
    z-index: 999999;

    &.search_active {
      bottom: -0.2604rem;
      opacity: 1;
    }

    .search_input {
      flex: 1;
      height: .2604rem;

      &>input {
        margin: 0;
        padding: 0 .0625rem 0 .0833rem;
        width: 100%;
        height: 100%;
        font-size: .0729rem;
        font-weight: 400;
        color: #171717;
        border: none;
        background-color: transparent;
        outline: none;

        &::placeholder {
          font-size: .0729rem;
          font-weight: 400;
          color: #9C9C9C;
        }
      }
    }

    .btn_search {
      display: flex;
      justify-content: center;
      align-items: center;
      width: .2604rem;
      height: .2604rem;
      background-color: #EAEAEA;
      cursor: pointer;

      .btn_search_img {
        width: .125rem;
        height: .125rem;
      }
    }
  }

  .user_box {
    position: absolute;
    // right: 0;
    bottom: .5208rem;
    display: flex;
    flex-direction: column;
    width: .5417rem;
    // height: .5417rem;
    height: .2708335rem;
    background-color: #FAFAFA;
    box-shadow: 0px 6px 18px 0px rgba(0,0,0,0.03);
    transition: bottom 300ms;
    opacity: 0.5;
    z-index: 999999;

    &.user_active {
      bottom: -.2708335rem;
      opacity: 1;
    }

    .user_box_item {
      // flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: .2708335rem;
      font-size: .0729rem;
      font-weight: 400;
      color: #000;
      line-height: .1042rem;
      white-space: nowrap;

      &:hover {
        background-color: #EAEAEA;
      }
    }

    .user_box_divider {
      display: flex;
      padding: 0 .0521rem;
      width: 100%;

      .user_box_divider_box {
        width: 100%;
        height: .0052085rem;
        background-color: #EAEAEA;
      }
    }
  }
}

.header_ccupy {
  min-height: .46875rem;
  height: .46875rem;
}