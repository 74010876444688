.home_custom_development {
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;

  .main_box {

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: .4167rem;
      
      span:nth-child(1) {
        font-size: .25rem;
        font-weight: 500;
        color: #C90D17;
        line-height: .3542rem;
      }
      span:nth-child(2) {
        font-size: .125rem;
        font-weight: 400;
        color: #999999;
        line-height: .1771rem;
      }
      span:nth-child(3) {
        margin-top: .2083rem;
        max-width: 4.7292rem !important;
        font-size: 15px;
        font-weight: 400;
        color: #666;
        line-height: 27px;
        max-width: 6.875rem;
        text-align: center;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: .2083rem;
      margin-bottom: .8438rem;

      .custom_development_list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 6.875rem;

        .custom_development_item {
          position: relative;
          display: flex;
          flex-direction: row;
          margin-top: .1042rem;
          padding: 24px;
          width: 3.3854166rem;
          height: 126px;
          border: 1px solid #FFF;
          background-color: #FFF;
          border-radius: 8px;
          box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.03);
          overflow: hidden;
          cursor: pointer;
          box-sizing: border-box;

          &:hover {
            // border: 1px solid #C90D17;
            box-shadow: 0 0 8px -2px #C90D17;
          }

          .custom_development_img {
            width: .2395835rem;
            height: .2395835rem;
          }

          .custom_development_box {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-left: .0833rem;

            .custom_development_title {
              font-size: .125rem;
              font-weight: 400;
              color: #171717;
              line-height: .1771rem;
            }

            .custom_development_content {
              margin-top: .0208rem;
              font-size: 14px;
              font-weight: 400;
              color: #666;
              line-height: 20px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              text-align: justify;
              overflow: hidden;
            }
          }
        }
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .3125rem;
        width: .5938rem;
        height: .25rem;
        background-color: #C90D17;
        border-radius: 4px;
        cursor: pointer;

        span {
          font-size: .0833rem;
          font-weight: 500;
          color: #FFF;
          line-height: .1406rem;
        }
      }
    }
  }
}