.web_navigation_main_m {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;

  &>.btn_box {
    // position: sticky;
    // top: 0;
    // left: 0;
    padding: .64rem .426666rem;
    z-index: 998;
    &>.btn_box_row {
      display: flex;
      flex-direction: row;
      align-items: center;
      &.margin_top {
        margin-top: .32rem;
      }
      &.justify_content_end {
        justify-content: end;
      }

      &>.btn {
        padding: .08rem .426666rem;
        background-color: #FAFAFA;
        border: 1px solid #CCC;
        border-radius: 4px;
        font-size: .373333rem;
        font-weight: 400;
        color: #171717;
        line-height: .64rem;
        &.margin_left {
          margin-left: .32rem;
        }
      }

      &>.btn_add {
        background-color: #C90D17;
        border: 1px solid #C90D17;
        color: #FFF;
      }
      &>.btn_operate {
        background-color: #FF4D4F;
        border: 1px solid #FF4D4F;
        color: #FFF;
      }
    }
  }

  &>.web_box {
    padding: 0 .426666rem;
    .web_box_list_1 {
      display: flex;
      flex-direction: column;
      &>.web_box_item_1 {
        display: flex;
        flex-direction: column;
        background-color: #FAFAFA;
        &>.web_box_item_title {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: .586666rem;
          &>.divider {
            width: .106666rem;
            height: .48rem;
            background-color: #C90D17;
          }
          &>span {
            margin-left: .266666rem;
            font-size: .426666rem;
            font-weight: 400;
            color: #171717;
            line-height: .426666rem;
          }
          &>.icons {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: .266666rem;
            width: .426666rem;
            height: .426666rem;
            &>img {
              width: 100%;
              height: 100%;
            }
          }
        }
        &>.web_box_item_list {
          display: flex;
          flex-direction: column;
          padding-bottom: .853333rem;
          &>.web_box_item_list_item {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: .32rem;
            padding: 0 .426666rem;
            height: 1.546666rem;
            background-color: #FAFAFA;
            border: 1px solid #EAEAEA;
            border-radius: 8px;
            // &.doudong {
            //   animation: doudong 1s infinite;
            // }
            &>.icon {
              width: .693333rem;
              height: .693333rem;
            }
            &>span {
              margin-left: .32rem;
              width: 100%;
              font-size: .426666rem;
              font-weight: 500;
              color: #171717;
              line-height: .426666rem;
            }
            &>.right_top_img {
              position: absolute;
              top: .213333rem;
              right: .213333rem;
              width: .426666rem;
              height: .426666rem;
            }
          }
        }
      }
    }
    .web_box_list_2 {
      display: flex;
      flex-direction: column;
      &>.web_box_item_2 {
        display: flex;
        flex-direction: column;
        &>.web_box_item_title {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: .586666rem;
          &>.divider {
            width: .106666rem;
            height: .48rem;
            background-color: #C90D17;
          }
          &>span {
            margin-left: .266666rem;
            font-size: .426666rem;
            font-weight: 400;
            color: #171717;
            line-height: .426666rem;
          }
        }
        &>.web_box_item_list {
          display: flex;
          flex-direction: column;
          padding-bottom: .853333rem;
          &>.web_box_item_list_item {
            position: relative;
            display: flex;
            flex-direction: row;
            margin-top: .32rem;
            padding: .426666rem;
            height: 3.306666rem;
            border: 1px solid #EAEAEA;
            border-radius: 8px;
            &.margin_top {
              margin-top: .64rem;
            }
            &>.icon {
              width: 1.333333rem;
              height: 1.333333rem;
            }
            &>.content_box {
              display: flex;
              flex-direction: column;
              margin-left: .32rem;
              &>span:nth-child(1) {
                width: 100%;
                font-size: .426666rem;
                font-weight: 500;
                color: #171717;
                line-height: .586666rem;
              }
              &>span:nth-child(2) {
                margin-top: .106666rem;
                width: 100%;
                font-size: .346666rem;
                font-weight: 500;
                color: #666;
                line-height: .586666rem;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-align: justify;
              }
            }
          }
        }
      }
    }
  }
}

.btn_menu_popup {
  position: fixed;
  bottom: 3.946666rem;
  right: .266666rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.333333rem;
  height: 1.333333rem;
  background-color: #C90D17;
  border-radius: 50%;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  &>img {
    min-width: .64rem;
    min-height: .64rem;
    width: .64rem;
    height: .64rem;
  }
}
.my_popup {
  .popup_body {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .popup_item {
      display: flex;
      flex-direction: column;
      width: 100%;
      &>.popup_item_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: .8rem;
        height: 1.226666rem;
        &:hover {
          background-color: #ECF5FF;
          &>span {
            color: #C90D17;
          }
        }
        &.active {
          background-color: #ECF5FF;
          &>span {
            color: #C90D17;
          }
        }
        &>img {
          width: .533333rem;
          height: .533333rem;
        }
        &>span {
          margin-left: .266666rem;
          font-size: .426666rem;
          font-weight: 400;
          color: #171717;
          line-height: .426666rem;
        }
      }
      &>.popup_item_list {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 0;
        background-color: rgba(255, 255, 255, .5);
        transition: all 500ms;
        overflow: hidden;
        &>.popup_item_list_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: 1.6rem;
          width: 100%;
          min-height: 1.12rem;
          height: 1.12rem;
          font-size: .373333rem;
          font-weight: 400;
          color: #171717;
          line-height: .373333rem;

          &:hover {
            color: #C90D17;
          }
        }
      }
    }
  }
}

@keyframes doudong {

  0%,
  100% {
    transform: rotateZ(0.35deg);
  }

  25%,
  75% {
    transform: rotateZ(-0.35deg);
  }

  50% {
    transform: rotateZ(0.35deg);
  }
}
.blue {
  border: 1px dashed #C90D17;
  border-radius: 8px;
}