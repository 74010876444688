.about_us_introduce {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: .7813rem 0;
  background-color: #FAFAFA;
  min-width: 6.875rem;

  .about_us_introduce_left {
    // padding: .625rem 0 .3125rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: .6615rem;
    width: 2.7865rem;

    .about_us_introduce_left_content {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 60px 0;

      .about_us_introduce_left_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        height: .5938rem;

        .about_us_introduce_left_img {
          margin-left: .1667rem;
          width: .3125rem;
          height: .3125rem;
        }

        .about_us_introduce_left_box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: .125rem;

          .about_us_introduce_left_title {
            font-size: .1875rem;
            font-weight: 700;
            color: #C90D17;
            line-height: .2448rem;
            white-space: nowrap;
          }
          .about_us_introduce_left_vice_title {
            font-size: .0781rem;
            font-weight: 400;
            color: #666;
            line-height: .1406rem;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .about_us_introduce_right {
    padding-left: .3125rem;
    width: 3.776rem;

    .about_us_introduce_right_title {
      font-size: .25rem;
      font-weight: 500;
      color: #171717;
      line-height: .349rem;
    }
    .about_us_introduce_right_content {
      margin-top: .3125rem;
      font-size: 15px;
      font-weight: 400;
      color: #666;
      line-height: 27px;
      white-space: pre-wrap;
      text-align: justify;
    }
  }
}