.pd_main_m {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 .426666rem;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  
  &>.pd_main_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    
    &>.pd_main_box_header {
      display: flex;
      flex-direction: column;
      padding: .32rem 0 .533333rem;
      
      &>.title {
        font-size: .533333rem;
        font-weight: 700;
        color: #171717;
        line-height: .613333rem;
        word-break: break-word;
      }
      
      &>.time {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: .426666rem;
        
        &>img {
          width: .48rem;
          height: .48rem;
          flex-shrink: 0;
        }
        
        &>span {
          margin-left: .16rem;
          font-size: .32rem;
          font-weight: 400;
          color: #999999;
          line-height: .426666rem;
        }
      }
      
      &>.jianjie {
        margin-top: .426666rem;
        font-size: .373333rem;
        font-weight: 400;
        color: #999999;
        line-height: .48rem;
        word-break: break-word;
      }
    }
    
    &>.pd_main_box_content {
      padding: .533333rem 0;
      height: max-content;
      word-break: break-word;
      
      img {
        max-width: 100% !important;
        height: auto !important;
        display: block !important;
        margin: 1rem auto !important;
      }
      
      p {
        text-align: center;
        img {
          margin: .5rem auto !important;
        }
      }
      
      * {
        max-width: 100% !important;
        box-sizing: border-box;
      }
    }
  }
}